.header {
    background-color: #000000;
    border-bottom: 6px solid #e21838;
    color: #FFFFFF;
    margin-bottom: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 100px;
    font-size: 1.25rem;
    font-weight: bold;

    .left, .right {
        flex: 1;
        max-width: 25vw;
        display: flex;
        justify-content: center;
    }

    .modeSelector {
        align-content: center;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        user-select: none;
        width: 100%;

        & .selected {
            text-decoration: underline;
        }
    }
}
