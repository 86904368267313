@import '../../sass/box-shadow.scss';

.bingoSquare {
    background-color: white;
    -webkit-transition: background-color 0.075s ease-in, color 0.075s ease-in;
    -moz-transition: background-color 0.075s ease-in, color 0.075s ease-in;
    -o-transition: background-color 0.075s ease-in, color 0.075s ease-in;
    transition: background-color 0.075s ease-in, color 0.075s ease-in;
    user-select: none;
    font-size: min(64px, 10vw);
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    
    &::before {
        content: "";
        padding-bottom: 100%;
        display: block;
    }
}

.label {
    background-color: inherit;
    color: white;
    text-shadow: boxShadow(1, 6, #e21838);
    font-size: min(135px, 15vw);
}

.freeSpace {
    font-size: min(48px, 6vw);
    color: #e21838;
}

.selected {
    background-color: #fba900;
    color: #000000;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}